const english = {
    welcomeMsg: "Welcome",
    labelAction: "Action",
    labelAnalyze: "Analyze",
    labelAnalyzed: "Analyzed",
    labelAlmost: "You're almost there!",
    labelAnInconsistencyWasIdentifiedInTheDocument: "An inconsistency was identified in the document. Please reload it again to continue with the analysis.",
    labelApply: "Apply",
    labelApplyQuestionnaire: "Apply questionnaire",
    labelApplyingQuestionnaire: "Applying questionnaire",
    labelApplyingQuestionnaireWait: "Applying questionnaire, please wait.",
    labelAskTheAi: "Ask AI",
    labelAttentionDependingOnTheSpeed: "Attention, depending on the speed of your Internet connection and the size of the uploaded file, this operation may take a few minutes.",
    labelAttentionPDFDisplayFailed: "An error occurred while loading the PDF. Click here to try again",
    labelAll: "All",
    labelBack: "Back",
    labelCancel: "Cancel",
    labelConfirm: "Confirm",
    labelConsult: "Consult",
    labelCreate: "Create",
    labelClose: "Close",
    labelCloseHistory: "Close history",
    labelCloseSidebar: "Close sidebar",
    labelClearSelection: "Clear selection",
    labelCharacter: "character",
    labelCharacters: "characters",
    labelCopy: "Copy text",
    labelDarkTheme: "Dark Theme",
    labelDelete: "Delete",
    labelDeleteHistory: "Delete history",
    labelDescription: "Description",
    labelDescriptionDocumentNote: "Description (Unique for multiple documents)",
    labelDescriptionOrQuestionId: "Description, ID or enter a new question to register",
    labelDocumentTypeAlreadyExists: "This document type already exists",
    labelDocuments: "Documents",
    labelDocumentName: "Document name",
    labelDocumentType: "Document type",
    labelDocumentNameOrDescription: "Document name or description",
    labelDocumentHasAlreadyBeenStandardizedPreviously: "Document has already been standardized previously. <br/> Please wait, you will be redirected to the home page.",
    labelDownloadCsv: "Download CSV",
    labelDownloadSuccessfully: "Download successfully",
    labelDocumentTranscript: "Document transcription",
    labelDuplicatedQuestionnaire: "Duplicated questionnaire name",
    labelEdit: "Edit",
    labelEdited: "Edited",
    labelEditedFail: "Edit failed",
    labelEditQuestion: "Edit question",
    labelEditType: "Edit type",
    labelExceedDesc: "Description exceeded the 250 character limit",
    labelExpandHistory: "Expand history",
    labelExpand: "Expand",
    labelFillInThisField: "Fill in this field.",
    labelFindingTheBestAnswer: "Finding the best answer",
    labelFailedToNormalize: "Failed to normalize",
    labelFailedNoResponse: "Failed, no response from the server.",
    labelFailedToLoadHistory: "Failed to load history.",
    labelFailedToApplyQuestionnaire: "Failed to apply questionnaire.",
    labelGeneralInformation: "General information",
    labelHistoric: "Historic",
    labelInclusionDate: "Inclusion date",
    labelListing: "Listing",
    labelLoading: "Loading",
    labelLines: "Lines per page",
    labelLoadingTheText: "Loading the text",
    labelLoadingFilePleaseWait: "Loading file, please wait",
    labelManage: "Manage",
    labelMostRecent: "Most recent",
    labelMostOlder: "Most older",
    labelModel: "Models",
    labelName: "Name",
    labelNew: "New",
    labelNewType: "Create type",
    labelNewQuestion: "Create question",
    labelNewQuestionnaire: "Create questionnaire",
    labelNotice: "Notice",
    labelNoFileChosen: "No file chosen",
    labelNoQuestionsRegistered: "There are no questions registered",
    labelNoMoreQuestionsAvailable: "There are no more questions available",
    labelNoDocumentsWereFound: "No documents were found",
    labelNoDocumentTypeWasFound: "No document type was found",
    labelNoQuestionsWereFound: "No questions were found",
    labelNoQuestionsWereSelected: "No questions were selected.",
    labelNoQuestionnairesWereFound: "No questionnaires were found",
    labelNotAnalyzed: "Not analyzed",
    labelNotAllowed: "Not allowed",
    labelNormalizingTheDocument: "Normalizing the document, wait.",
    labelNumberOfPagesHasBeenExceeded: "Number of pages has been exceeded. Contact your plan administrator.",
    labelNumberOfQuestionsHasBeenExceeded: "Number of questions has been exceeded. Contact your plan administrator.",
    labelOutput: "Output",
    labelOrder: "Order",
    labelOwner: "Owner",
    labelPreparingTheDocument: "Preparing the document..",
    labelPdfBack: "Back to PDF",
    labelQueryWithoutHistory: "Query without history",
    labelQuestions: "Questions",
    labelQuestionAlreadyExists: "This question already exists",
    labelQuestionNotFound: "Question not found",
    labelQuestionnaires: "Questionnaires",
    labelQuestionnaireSearch: "Name, ID , Document type or insert a new questionnaire to register",
    labelQuestionnaireAppliedSuccessfully: "Questionnaire applied successfully, please wait.",
    labelQuestionnaireAndAi: "Questionnaire and AI",
    labelReduceHistory: "Reduce history",
    labelReprocess: "Reprocess",
    labelSave: "Save",
    labelSend: "Send",
    labelSendQuestion: "Send question",
    labelSendingTheDocument: "Sending the document, please wait.",
    labelSelect: "Select",
    labelSelectAFile: "Select a file.",
    labelSelectAnItemFromTheList: "Select an item from the list.",
    labelSelected: "Selected",
    labelSelecteds: "Selected",
    labelSelectQuestions: "Select questions",
    labelSelectQuestionnaire: "Select a questionnaire",
    labelSearchDocument: "Search document",
    labelSearchTypes: "Search types",
    labelSearchQuestion: "Search question",
    labelSearchQuestionnaire: "Search questionnaire",
    labelSignOut: "Sign out",
    labelStatus: "Status",
    labelShowingToTotal: "of",
    labelShowingFilesUpload: "uploaded files",
    labelStandardizedFullText: "Standardized full text",
    labelTypes: "Types",
    labelTextCopiedToClipboard: "Text copied to clipboard.",
    labelThisActionCannotBeUndone: "This action cannot be undone. <br/> Are you sure you want to <br/> remove it?",
    labelTypeNameOrId: "Name, ID or enter a new type to register",
    labelTheFileMayBeCorrupt: "The file may be <br/> corrupt or have an error. <br/> Please try again.",
    labelTheFileMayBeUnreadableOrHaveAnError: "The file may be <br/> unreadable or have an error. <br/> Please try again.",
    labelThereIsNotEnoughCredit: "There is not enough credit to apply all questions in this questionnaire.",
    labelTypeDoc: "Document Type",
    labelUpload: "Upload",
    labelUploadPdf: "Drag and drop your PDF files below to get started or click on the area to browse",
    labelUploadFailure: "Upload failure",
    labelUploadedFiles: "All files are uploaded!",
    labelUploadedFilesError: "Fail to upload files!",
    labelUploadComplete: "Upload complete",
    labelUploadError: "Something is wrong",
    labelViewQuestions: "View questions",
    labelViewHistory: "View history",
    labelYouAreAboutToDeleteDocument: "You are about to delete <br/> the document from the system",
    labelYouAreAboutToDeleteDocumentType: "You are about to delete <br/> a document type from the system",
    labelYouAreAboutToDeleteDocumentQuery: "You are about to delete <br/> the query history from the system",
    labelYouAreAboutToDeleteQuestion: "You are about to delete <br/> a question from the system",
    labelYouAreAboutToDeleteQuestionnaire: "You are about to delete <br/> a questionnaire from the system",
    labelGoHome: "Go to home",
    labelRemoveAllDropzone: "Remove files from dropzone",
    labelRemoveAllFilesDropzone: "Remove all files from the queue?",
    labelThisActionRemoveAllFiles: "This action will remove all files selected for upload",
    labelCaution: "Caution!",
    labelNotReloadThePage: "To avoid interrupting the upload process, do not reload the page",
    labelFailed: "failed!",
}

export default english;
